import Section from "./section";
import Main from "./main";  
import Header from '../../components/Navbar/navbar';
import InfoSection from '../../components/Info/info';
import Footer from '../../components/Footer/footer';
import  '../../css/blogs.css';

function Blogs() {
  return (
    <div>
      <Header />
      <Main/>
        <Section />
        <InfoSection />
      <Footer />
    </div>
  );
}

export default Blogs;

