import React from 'react';
import Nvidia from '../../static/logo3.png';

function App() {
  return (
    <div className='bg-white pt-9'>
      {/* Heading */}
      <div className='w-full'>
        <div>
          <h2 className='text-4xl font-bold text-center'>Trusted by industry-leading companies worldwide</h2>
        </div>
      </div>

      {/* Logos */}
      <div className='flex flex-wrap md:gap-x-20 justify-center items-center mt-1'>
        <div className='m-4'>
        <img width="100" height="100" src="https://img.icons8.com/color/120/trustpilot.png" alt="trustpilot"/>
        </div>
        <div className='m-4'>
          <img width="120" height="100" src='https://www.advancedmd.com/wp-content/uploads/2017/10/New-Clutch-logo_Dark-Blue.png' alt="Tencent"  />
        </div>
        <div className='m-4'>
          <img src={Nvidia} alt="Nvidia" width="120" height="100"  />
        </div>
        <div className='m-4'>
          <img src='https://bg.systweak.com/blogs/wp-content/uploads/2022/01/top-software-development-companies.png' alt="Facebook" width="180" height="100"   />
        </div>
      </div>
    </div>
  );
}

export default App;
