import Header  from "../../components/Navbar/navbar";
import React from "react";
import Macbook from "../../static/macbook.png";
import Apple from '../../static/apple.png';
import Google from  '../../static/google.png';
import chowdeck from "../../static/chowdecks.png";
import Analysis from "../../static/analysis.png";
import Next from '../../static/next.png';
import Amazon from '../../static/amazonweb.png';
import Blacky from '../../static/blacky.png';
import Pyramidia from '../../static/pyramidia.png';
import FooterSection from "../../components/Footer/footer";
import InfoSection from "../../components/Info/info";
function Case () {
    return(
        <>
        <Header />
        <div className=" bg-[#E7C200] pt-14">
                <div className=" w-full h-fit bg-[#E7C200]  py-10 md:py-20 flex flex-col-reverse md:gap-0 gap-10 md:flex-row md:flex items-center justify-between overflow-hidden ">
                <div className=" w-[90%] md:w-[49%] md:pl-14" data-aos="fade-right">
                <div className=' mb-8 flex items-center ' >
                        <span><img src={chowdeck} className=' mr-2' alt='chowdeck'/></span>
                        <h2 className="text-4xl font-normal text-white">Chowdeck</h2>
                    </div>
                    <p className="text-white mb-5 font500">
                        <span>Chowdeck is a technology company that </span> <br />
                        <span>provides logistics services to both vendors</span><br />
                        <span>and consumers.</span>
                    </p>
                    <p className='  text-white  text-sm'>
                    KPA remains committed to driving innovation and <br/> transforming the logistics landscape for the betterment of <br/> industry experts.
                    </p>
                    <h6 className="font-bold text-sm mb-1 pt-6">
                        <span>DOWNLOAD OUR</span><br /><span>APP</span>
                    </h6>
                    <div className="flex gap-2 mb-4">
                        <img src={ Apple } alt="apple" className="w-32" />
                        <img src={ Google} alt="google" className="w-32" />
                    </div>
                    <button className="px-4 py-3 bg-[white] rounded-lg text-black font-sans text-sm font-bold">
                    Explore More
                    </button>
                                    
                </div>
                <div className=' md:w-1/2 w-full md:block flex justify-center     ' data-aos="fade-left">
                <img src={Macbook} alt=''  className=' md:w-3/5 w-4/5 hover:scale-[1.012] transition-[3s]'/>
                </div>
            </div>
            <div className=" flex flex-row  justify-center gap-[30px]">
                    
            </div>
            {/* Challenges and solutions  */}
            <div className=" bg-slate-200">
                <div>
                    <h2 className=" pt-12 text-4xl text-center  font-[500]"> <span className=" text-[#029CCD]">Challenges</span> and <span className=" text-[#029CCD]">Solutions</span></h2>
                    <p className=" text-center text-sm">By addressing these challenges through thoughtful design and development strategies,<br /> Chowdeck's platform can overcome obstacles and deliver a seamless logistics experience for<br /> vendors and consumers alike.</p>
                    <div className=" w-full flex justify-center ">
                        <div className=" w-[720px]  flex md:flex-row justify-center items-center gap-12 flex-col">
                            <div className=" md:w-[340px] w-[80%]">
                                <h2 className=" text-2xl text-center py-2"> Challenges</h2>
                                <div className=" w-full bg-white   rounded-2xl shadow-md">
                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 ">Complex Logistics Workflow</h2>
                                        <p className=" text-sm  pb-2 ">Creating a platform for logistics operations, involving tasks like order management, route optimization, and real-time tracking, presents significant challenges.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 ">User Experience</h2>
                                        <p className=" text-sm  pb-2 ">Balancing functionality with user experience poses a challenge, as the platform needs to be intuitive and easy to use for both vendors and consumers.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 ">Scalability</h2>
                                        <p className=" text-sm  pb-2 ">Ensuring that the platform can scale to accommodate a growing user base and increasing transaction volumes without sacrificing performance or reliability.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 ">Integration Complexity</h2>
                                        <p className=" text-sm  pb-2 ">integrating the platform with existing systems and third-party services, such as payment gateways or inventory management systems, can pose integration challenges and compatibility issues.</p>
                                        
                                    </div>

                                </div>
                            </div>

                            <div className="md:w-[340px] w-[80%]">
                                <h2 className=" text-2xl text-center py-2"> Solutions</h2>
                                <div className=" w-full bg-white   rounded-2xl shadow-md">
                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 text-blue-400">Modular Design</h2>
                                        <p className=" text-sm  pb-2 ">We adopt a modular design, breaking the platform into manageable components for independent development and testing, providing flexibility and scalability for complex logistics workflows.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 text-blue-400 ">User-Centric Design</h2>
                                        <p className=" text-sm  pb-2 ">We prioritize user experience through thorough research and testing, designing the platform for simplicity, clarity, and ease of use with intuitive interfaces and navigation.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 text-blue-400">Scalability Planning</h2>
                                        <p className=" text-sm  pb-2 ">We start with scalable architecture and cloud-based solutions, continuously optimizing performance to accommodate growth.</p>
                                        
                                    </div>

                                    <div className=" border-[1.7px] px-3">
                                        <h2 className=" text-lg pt-4 text-blue-400">API-Based Integration</h2>
                                        <p className=" text-sm  pb-2 ">integrating the platform with existing systems and third-party services, such as payment gateways or inventory management systems, can pose integration challenges and compatibility issues.</p>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
            <div className=" bg-slate-200 w-full">
                <div>
                <h2 className=" text-4xl text-center pt-16 font-semibold">Mobile <span className="text-[#029CCD] ">Navigation</span> </h2>
                </div>
                <div>
                    <img src={Analysis} alt="analysis " className=" h-[270px] sm:h-auto"></img>
                </div>
            </div>
            <div className=" bg-slate-200 w-full flex items-center justify-center flex-col py-5">
                <div className=" w-full sm:w-[600px]">
                    <h2 className=" text-4xl text-center pt-16">Utilized <span className="text-[#029CCD] ">Technologies</span> </h2>
                    <p className=" text-center">Here’s a variety of technologies employed to ensure functionality, performance, and usability of the platform. These technologies encompassed both frontend and backend components, as well as infrastructure and tools necessary for deployment and maintenance.</p>
                </div>
                <div className="flex flex-row flex-wrap justify-center items-center">
                    <img src={Next} alt="Next" className="w-72"></img>
                    <img src={Amazon} alt="Amazon" className="w-72"></img>
                    <img src={Blacky} alt="Blacky" className="w-72"></img>
                    <img src={Pyramidia} alt="Pyramidia" className="w-72"></img>
                </div>

            </div>
        </div>
        <InfoSection/>
        <FooterSection/>
        </>
    )
}

export default Case;