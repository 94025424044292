import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const fullText1 = "Building and deploying";
  const fullText2 = "innovative mobile and web solutions";

  useEffect(() => {
    if (index1 < fullText1.length) {
      const timer1 = setTimeout(() => {
        setText1((prev) => prev + fullText1[index1]);
        setIndex1(index1 + 1);
      }, 100);
      return () => clearTimeout(timer1);
    }
  }, [index1, fullText1]);

  useEffect(() => {
    if (index1 === fullText1.length && index2 < fullText2.length) {
      const timer2 = setTimeout(() => {
        setText2((prev) => prev + fullText2[index2]);
        setIndex2(index2 + 1);
      }, 100);
      return () => clearTimeout(timer2);
    }
  }, [index2, fullText2, index1]);

  const handleBoxClick = (event) => {
    // Add the animation class to the clicked box
    const box = event.currentTarget;
    box.classList.add('animate-click');

    // Remove the animation class after the animation ends
    box.addEventListener('animationend', () => {
      box.classList.remove('animate-click');
    }, { once: true });
  };

  return (
    <div className="w-full min-h-screen pt-[70px] background-image text-white flex flex-col justify-between">
      <div className="md:pt-24 pt-12 md:pb-1 pb-10 text-center">
        <h2 className="text-5xl font-bold">{text1}</h2>
        <h2 className="text-4xl lg:text-6xl font-bold text-gradient">{text2}</h2>
      </div>

      <div className="w-full max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        {/* Box 1 */}
        <div className="bg-main p-6 rounded-lg shadow-md flex flex-col justify-between h-auto lg:h-full animate-fade-in transform transition-transform hover:scale-105" onClick={handleBoxClick}>
          <div>
            <h3 className="text-xl lg:text-2xl font-thin mb-4 text-blue-500">Website Development</h3>
            <p className="text-base lg:text-lg mb-4">Our team of skilled designers and developers create stunning websites that are not only visually appealing but also user-friendly.</p>
          </div>
          <a href="/portfolio?tab=Web%20Application" className="text-blue-500 hover:underline">Learn more</a>
        </div>

        {/* Box 2 */}
        <div className="bg-main p-6 rounded-lg shadow-md flex flex-col justify-between h-auto lg:h-full animate-fade-in transform transition-transform hover:scale-105" onClick={handleBoxClick}>
          <div>
            <h3 className="text-xl lg:text-2xl font-thin mb-4 text-blue-500">Google AdSense</h3>
            <p className="text-base lg:text-lg mb-4">Our team of skilled designers and developers create stunning websites that are not only visually appealing but also user-friendly.</p>
          </div>
          <a href="/portfolio?tab=Google%20Monetization" className="text-blue-500 hover:underline">Learn more</a>
        </div>

        {/* Box 3 */}
        <div className="bg-main p-6 rounded-lg shadow-md flex flex-col justify-between h-auto lg:h-full animate-fade-in transform transition-transform hover:scale-105" onClick={handleBoxClick}>
          <div>
            <h3 className="text-xl lg:text-2xl font-thin mb-4 text-blue-500">Mobile App Development</h3>
            <p className="text-base lg:text-lg mb-4">We specialize in creating high-quality mobile applications that meet your business needs.</p>
          </div>
          <a href="/portfolio?tab=Mobile%20Application" className="text-blue-500 hover:underline">Learn more</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
