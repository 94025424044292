import React, {useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import "../../css/ext.css";
import Chatround from "../../static/chat-round.png";
import Overlay from '../OverLay/overlay';
import Code from '../../static/code.jpeg';
import appDesign from '../../static/app.jpeg';
import Computer from '../../static/computer.jpeg';
import WhatsApp from  '../../static/messaging.webp';
import Email from  '../../static/messaging (1).webp';




function InfoSection() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [ showSecondOverlay, setShowSecondOverlay]=useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'phone') {
      setPhone(value);
    } else if (name === 'services') {
      setService(value);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };


const returnOverlay = () =>{
  setShowSecondOverlay(false);
  setShowOverlay(!showOverlay);
};


  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const toggleSecondOverlay =() =>{
   setShowOverlay(false);
   setShowSecondOverlay(!showSecondOverlay);
  }

  const isFormValid = name !== '' && email !== '' && phone !== '' && service !== '';

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const sendEmail = (templateParams) => {
    emailjs.send('service_u50c5w8', 'template_mfayepo', templateParams, 's0dR4kzVgKwALyAft')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
};

const sendAutoResponse = (templateParams) => {
    emailjs.send('service_u50c5w8', 'template_v3xat9m', templateParams, 's0dR4kzVgKwALyAft')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
};

const handleSubmit = () => {
  setIsLoading(true);

  const userInfoTemplateParams = {
    from_name: name,
    from_email: email,
    from_phone: phone,
    from_service: service,
  };
  sendEmail(userInfoTemplateParams);

  const autoResponseTemplateParams = {
    from_email: email,
    from_name: name,
  };
  sendAutoResponse(autoResponseTemplateParams);

  // Reset form fields after submission
  setName('');
  setEmail('');
  setPhone('');
  setService('');

  // Simulate loading for 2 seconds before navigating
  setTimeout(() => {
    setIsLoading(false);
    navigate('/approved');
  }, 2000);
};



  return (
    <section className=''>
    <div className="w-full flex md:flex-row flex-col md:justify-between justify-center items-center ci pr-12">
        <div className="md:w-1/4 md:pl-10 py-5 px-2" data-aos="fade-up">
        <img src={Chatround} alt="chatround" />

          <h2 className="text-white text-xl font-semibold">Get in touch with us today.</h2>
          <p className="text-white font-thin">We're dedicated to providing exceptional service and support to every client, and we can't wait to assist you with your digital needs.</p>
        </div>
        <div className="bg-white p-6 md:w-[400px] pr-5 w-[95%]" style={{ borderRadius: '10px' }} data-aos="fade-up">
          <p id='message'></p>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input type="text" id="name" name="name" className="mt-1 p-2 border border-gray-300 rounded-md w-full" value={name} onChange={handleInputChange} />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" id="email" name="email" className="mt-1 p-2 border border-gray-300 rounded-md w-full" value={email} onChange={handleInputChange} />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
            <input type="tel" id="phone" name="phone" className="mt-1 p-2 border border-gray-300 rounded-md w-full" value={phone} onChange={handleInputChange} />
          </div>
          <div className="mb-4">
          <label htmlFor="services" className="block text-sm font-medium text-gray-700">How did you get to know us?</label>
        <select id="services" name="services" className="mt-1 p-2 border border-gray-300 rounded-md w-full" value={service} onChange={handleInputChange}>
          <option value="" disabled>Select an option</option>
          <option value="facebook">Facebook</option>
          <option value="instagram">Instagram</option>
          <option value="Google">Google</option>

          <option value="referred_friend">Referred by a friend</option>
          <option value="other">Others</option>
        </select>

          </div>
          <div>
            {isFormValid && (
              <button onClick={toggleOverlay} className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-full py-3 rounded">
                Get Free Consultancy
              </button>
            )}
          </div>
        </div>
      </div>
  <Overlay show={showOverlay} Close={toggleOverlay}>
    <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className='text-center text-black pb-5'>
            <h2 className='text-3xl'>Giving your vision a smooth <br/> <span className='text-blue-500'>Transition To Reality</span></h2>
            <p className='text-gray-500'>By enabling an innovative digital experience that fulfills new-age demands</p>
          </div>
          <div className='flex md:flex-row gap-7 md:gap-0 items-center flex-col justify-center px-4 py-4'>
            <div className={`shadow-lg px-8 w-[90%] cursor-pointer   sm:w-[50%] md:w-[40%] hover:border-[1.5px] border ${selectedOption === 'mobile' ? 'border-blue-500' : 'border-white'} py-2 rounded-md`} onClick={() => handleOptionSelect('mobile')}>
              <img src={appDesign} className='h-16 mx-auto' alt='code' />
              <div>
                <h2 className='text-gray-500 font-semibold text-sm text-center py-1'>I Need A Mobile <br/> Application</h2>
              </div>
            </div>
            <div className={`shadow-lg px-8 w-[90%] cursor-pointer sm:w-[50%] md:w-[40%] hover:border-[1.5px] border ${selectedOption === 'web' ? 'border-blue-500' : 'border-white'} py-2 rounded-md`} onClick={() => handleOptionSelect('web')}>
              <img src={Code} alt='code' className='h-16 mx-auto' />
              <div>
                <h2 className='text-gray-500 font-semibold text-sm text-center py-1'>I Need A Web <br/> Application</h2>
              </div>
            </div>
            <div className={`shadow-lg cursor-pointer px-8 w-[90%] sm:w-[50%] md:w-[40%] hover:border-[1.5px] border ${selectedOption === 'custom' ? 'border-blue-500' : 'border-white'} py-2 rounded-md`} onClick={() => handleOptionSelect('custom')}>
              <img src={Computer} className='h-16 mx-auto' alt='code' />
              <div>
                <h2 className='text-gray-500 font-semibold text-sm text-center py-1'>I Need A Custom Website</h2>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center ">
          <div className="flex items-center">
            <div className="w-7 h-7 flex items-center justify-center rounded-full bg-blue-500 text-white mr-4">1</div>
            <div className="w-7 h-7 flex items-center justify-center rounded-full bg-gray-200 text-gray-800 mr-4">2</div>
            <div className="w-7 h-7 flex items-center justify-center rounded-full bg-gray-200 text-gray-800">3</div>
          </div>
          </div>
          
          
          
          <div className='mt-5 flex justify-between items-center'>
          
            <button onClick={handleCloseOverlay} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Back</button>
            {selectedOption && (
              <button onClick={toggleSecondOverlay} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2">Next</button>
            )}
          </div>

        </div>
      </div>
  </Overlay>

  

<Overlay show={showSecondOverlay} Close={toggleSecondOverlay} onSubmit={handleSubmit}>
  <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
  <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className='text-center text-black pb-5'>
          <h2 className='text-3xl'>Giving your vision a smooth <br/> <span className='text-blue-500'>Transition To Reality</span></h2>
          <p className='text-gray-500'>What's the best way for us to reach you to discuss project?</p>
        </div>
        <div className='flex md:flex-row gap-7 md:gap-0 items-center flex-col justify-center px-4 py-4'>
          <div className={`shadow-lg px-8 w-[90%] cursor-pointer sm:mx-6   sm:w-[50%] md:w-[40%] hover:border-[1.5px] border ${selectedOption === 'mobile' ? 'border-blue-500' : 'border-white'} py-2 rounded-md`} onClick={() => handleOptionSelect('mobile')}>
            <img src={WhatsApp} className='h-16 mx-auto' alt='code' />
            <div>
              <h2 className='text-gray-500 font-semibold text-sm text-center py-1'>Please <br/> Email  Me</h2>
            </div>
          </div>
          <div className={`shadow-lg px-8 w-[90%] cursor-pointer sm:mx-6 sm:w-[50%] md:w-[40%] hover:border-[1.5px] border ${selectedOption === 'web' ? 'border-blue-500' : 'border-white'} py-2 rounded-md`} onClick={() => handleOptionSelect('web')}>
            <img src={Email} alt='code' className='h-16 mx-auto' />
            <div>
              <h2 className='text-gray-500 font-semibold text-sm text-center py-1'>Please <br/> Call Me</h2>
            </div>
          </div>
          
        </div>
        <div className="flex items-center justify-center ">
        <div className="flex items-center">
          <div className="w-7 h-7 flex items-center justify-center rounded-full bg-blue-500 text-white mr-4">1</div>
          <div className="w-7 h-7 flex items-center justify-center rounded-full bg-blue-500 text-white mr-4">2</div>
          <div className="w-7 h-7 flex items-center justify-center rounded-full bg-gray-200 text-gray-800">3</div>
        </div>
        </div>
        
         
        
        <div className='mt-5 flex justify-between items-center'>
         
          <button onClick={returnOverlay} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Back</button>
          <button
              type='button'
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
              onClick={handleSubmit}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? (
                <>
                  Loading...
                  <span className="animate-ping ml-1">...</span> {/* Add animation */}
                </>
              ) : (
                'Next'
              )}
         </button>
        </div>

      </div>
  </div>
  </Overlay>




<div className="newsletter w-full flex flex-col md:flex-row md:justify-between items-center py-10">
<div className="w-full md:w-1/2 text-center md:text-left text-white mb-4 md:mb-0  md:pl-10" data-aos="fade-up">
  <h2 className="font-bold  mb-2 text-3xl" >
    Subscribe to our newsletter to get the latest news in your inbox.
  </h2>
  <p>By subscribing, you agree to our Privacy Policy and consent to receive updates from our company.</p>
</div>
<div className=" w-full md:w-1/2 pl-5" >
  <input
    type="email"
    placeholder="Your email address"
    className="mb-2 md:mb-0 mr-0 md:mr-2 p-3 border border-gray-300 rounded-md w-full md:w-4/5" 
  />
  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-1 right-[5px] p-2 px-4 rounded ml-0 md:ml-2 absolute md:right-[10%]">
    Subscribe
  </button>
</div>
</div>

</section>
  );
}

export default InfoSection;
