import React, { useState, useEffect } from 'react';
import Header from '../Navbar/navbar';

const ThankYouPage = () => {
    const [countdown, setCountdown] = useState(10); // Set initial countdown duration in seconds

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                // Redirect to home page after countdown finishes
                window.location.href = '/';
            }
        }, 1000);

        // Cleanup function to clear the timer
        return () => clearTimeout(timer);
    }, [countdown]);

    return (
        <>
        <Header />
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-4xl font-bold mb-4 text-blue-600">Thank You!</h1>
            <p className="text-lg text-gray-700 mb-8">We have received your information and will be in touch shortly.</p>
            <div className="text-xl font-bold mb-4">Redirecting in {countdown} seconds...</div>
        </div>
        </>
    
    );
}

export default ThankYouPage;
