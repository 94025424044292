import React from 'react';
import logo from "./Logo/Icon.png";
import logo2 from "./Logo/Icon (2).png";
import logo1 from "./Logo/Icon (1).png";
import logo3 from "./Logo/Icon (3).png";


import  '../../css/about.css';
import ContactOverlay from './overlay';
import { useState } from "react";


function WhyChooseUsSection() {
  const [showOverlay, setShowOverlay] = useState(false); // State to control the visibility of the overlay

  const handleKickstartClick = () => {
      setShowOverlay(true); 
  };

  
  return (
   <> 
    <section>
      
      <div> 
        <div></div>
        <div className="text-container pb-5">
          <h2 className="font-bold text-4xl text-center font-sans mb-4 pt-5" data-aos="fade-up">Why Choose Us</h2>
          <p className="text-center font-thin text-lg" data-aos="fade-up">People choose us because we serve the best for everyone</p>
        </div>
        <div className="polygon">
          <div className="octagon w-full h-full"></div>
        </div>
    </div>

    
      {/* Section 02 review */}
    <div className="w-full flex justify-center">
      <div className=" sm:grid  grid-cols-2 lg:w-4/6 gap-6 md:w-4/5 w912 ">
      <div className="box-shadow h-64 bg-white flex flex-col justify-center items-center text-center px-4 py-6 mb30  hover:scale-[1.05] transition-[3s]" data-aos="fade-up">
        <img src={logo} alt="logo" className='w-[60px] h-[60px] mb-4' />
        <h2 className="text-xl font-semibold mb-2">Unparalleled Expertise</h2>
        <p className="text-gray-600">Our team brings a wealth of experience and expertise to every project, ensuring exceptional results that exceed expectations.</p>
    </div>

    <div className="box-shadow h-64  bg-white flex flex-col justify-center items-center text-center px-4 py-6 mb30  hover:scale-[1.05] transition-[3s]" data-aos="fade-up">
        <img src={logo1} alt="logo" className='w-[60px] h-[60px] mb-4' />
        <h2 className="text-xl font-semibold mb-2">Innovative Solutions</h2>
        <p className="text-gray-600">We stay ahead of the curve with the latest technologies and trends, delivering innovative solutions that keep you ahead of the competition.</p>
    </div>

        <div className="box-shadow h-64 bg-white flex flex-col justify-center items-center text-center px-4 py-6 mb30  hover:scale-[1.05] transition-[3s]"data-aos="fade-up">
        <img src={logo2} alt="logo" className='w-[60px] h-[60px] mb-4' />
        <h2 className="text-xl font-semibold mb-2">Client-Centric Approach</h2>
        <p className="text-gray-600">Your success is our top priority, and we're committed to delivering personalized service and support every step of the way.</p>
    </div>
    
    <div className="box-shadow h-64 bg-white flex flex-col justify-center items-center text-center px-4 py-6 mb30 hover:scale-[1.05] transition-[3s]" data-aos="fade-up">
        <img src={logo3} alt="logo" className='w-[60px] h-[60px] mb-4' />
        <h2 className="text-xl font-semibold mb-2">Collaborative Partnership</h2>
        <p className="text-gray-600">We view ourselves as an extension of your team, working closely with you to understand your goals, challenges, and aspirations, and delivering solutions that drive tangible results.</p>
    </div>

      </div>
    </div>




  <div className="sm:flex  justify-center">
  <div className="pt-10 w-full md:w-5/6 grid grid-cols-1 md:grid-cols-3 gap-6">
  <div className="text-center" data-aos="fade-up">
    <h2 className="text-3xl font-bold font-roboto">1M+</h2>
    <div>
    <p className=" font-thin">Customers visit KPA.Tech every month to get their service done.</p>

    </div>
  </div>
  <div className="text-center" data-aos="fade-up">
    <h2 className="text-3xl font-bold font-roboto">92%</h2>
    <div>
    <p className=" font-thin">Satisfaction rate comes from our awesome customers.</p>
    </div>
  </div>
  <div className="text-center" data-aos="fade-up">
    <h2 className="text-3xl font-bold font-roboto">4.9/5.0</h2>
    <div>
    <p className=" font-thin">Average customer ratings we have got all over the internet.</p>
    </div>
  </div>
</div>

  </div>


      <div className="flex justify-center mt-7 py-10">
        <div className="w-5/6">
          <hr />
          <div className="mt-9 w-full flex flex-col items-center md:flex-row md:justify-between">
          <div className="mb-4 md:mb-0 md:mr-4 text-center md:text-left" data-aos="fade-up">
            <h2 className="font-bold text-2xl md:text-3xl">Ready to launch your next project?</h2>
            <p className="text-lg md:text-xl">Contact us today to learn more about how we can help you achieve your business objectives.</p>
          </div>
          <button className="bg-blue-500 text-white px-6 py-3 rounded font-inter font-semibold"onClick={handleKickstartClick} data-aos="fade-up">
            Kickstart your Project
          </button>
        </div>

        </div>
      </div>
    </section>
    {showOverlay && <ContactOverlay onClose={() => setShowOverlay(false)} />}
    </>
  );
}

export default WhyChooseUsSection;