import React from "react";
import Apple from '../../static/apple.png';
import Google from  '../../static/google.png';
import chowdeck from "../../static/Instas.svg";
import Insta from "../../static/insta.png";



function Instas(){

    return(
        <>
        <div className="md:w-[80%] sm:w-[90%] w-[90%] h-fit bg-[#7B11F9] box-shadow py-10 md:py-20 flex flex-col-reverse md:gap-0 gap-10 md:flex-row md:flex items-center justify-between overflow-hidden rounded-lg ">
      <div className=" w-[90%] md:w-[49%] md:pl-14"data-aos="fade-right" >
          <div className=' mb-8 flex items-center ' >
                <span><img src={chowdeck} className=' mr-2' alt='chowdeck'/></span>
               
            </div>
            <p className="text-white mb-5 font500">
                <span>A powerful platform designed to help you A powerful platform designed to help you </span> <br />
                <span>create campaigns, generate leads, and </span><br />
                <span>automatically engage with your prospects.</span>
            </p>
            <p className='  text-white  text-sm'>
            Through iterative development and continuous refinement, <br/>KPA ensured that the platform offers intuitive functionality, <br/> seamless user experience, and effective performance
            </p>
            <h6 className="font-bold text-sm mb-1 pt-6">
                <span>DOWNLOAD OUR</span><br /><span>APP</span>
            </h6>
            <div className="flex gap-2 mb-4">
                <img src={ Apple } alt="apple" className="w-32" />
                <img src={ Google} alt="google" className="w-32" />
            </div>
            <button className="px-4 py-3 bg-[white] rounded-lg text-black font-sans text-sm font-bold">
            Explore More
            </button>
                            
        </div>
        <div className=' md:w-1/2 w-full md:block flex justify-center  ' data-aos="fade-left">
          <img src={Insta} alt=''  className=' w-4/5 hover:scale-[1.012] transition-[3s]'/>
        </div>
      </div>
        </>
    )
}

export default Instas