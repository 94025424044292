import React, { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import logo from "./logo.png";
import ServicesDropdown from '../../pages/Home/ServicesDropdown';

const Header = () => {
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    let dropdownTimeout;

    const Links = [
        { name: "Home", link: "/" },
        { name: "Services", link: "#", hasDropdown: true },
        { name: "Portfolio", link: "/portfolio" },
        { name: "Blogs", link: "/blogs" },
        { name: "Contact Us", link: "/contact" },
    ];

    const handleMouseEnter = () => {
        clearTimeout(dropdownTimeout);
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        dropdownTimeout = setTimeout(() => {
            setDropdownOpen(false);
        }, 200);
    };

    const redirectAbout = () => {
        window.location.href = "/about";
    };

    return (
        <div className="shadow-md w-full fixed top-0 left-0 z-50 bg-white">
            <div className='md:flex items-center justify-between py-1 md:px-10 px-7'>
                {/* Logo section */}
                <div className="flex justify-center items-center gap-1 ml-2">
                    <a href="/">
                        <img src={logo} alt="logo" className="w-10 h-12" />
                    </a>
                    <h2 className="font-semibold font-sans text-black">KPA.Tech</h2>
                </div>

                {/* Menu icon */}
                <div onClick={() => setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                    {open ? <XMarkIcon /> : <Bars3BottomRightIcon />}
                </div>

                {/* Link items */}
                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                    {Links.map((link, index) => (
                        <li key={index} className='md:ml-7 pt-4 md:pt-0 md:my-0 my-3 font-semibold relative'
                            onMouseEnter={link.hasDropdown ? handleMouseEnter : null}
                            onMouseLeave={link.hasDropdown ? handleMouseLeave : null}>
                            {link.hasDropdown ? (
                                <a href={link.link} className="flex items-center text-gray-800 font-normal text-sm hover:text-blue-400 duration-500">
                                    {link.name}
                                    <ChevronDownIcon className={`ml-1 w-4 h-4 transition-transform duration-300 ${dropdownOpen ? 'transform rotate-180' : ''}`} />
                                </a>
                            ) : (
                                <a href={link.link} className="text-gray-800 font-normal text-sm hover:text-blue-400 duration-500">
                                    {link.name}
                                </a>
                            )}
                        </li>
                    ))}
                    <button className='bg-blue-600 text-white md:ml-8 font-semibold text-sm px-3 py-2 rounded duration-500 md:static' onClick={redirectAbout}>Get Started</button>
                </ul>
            </div>
            <div
                className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {dropdownOpen && (
                    <div className="absolute left-0 w-full">
                        <ServicesDropdown isVisible={dropdownOpen} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
