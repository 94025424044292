import logo from './logo.png';


function FooterSection() {
  

  return (
    <div>
      <div className='bg-[#161C2D] text-white'>
        <div className="w-[90%] mx-auto py-8 px-4 md:px-0">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div data-aos="fade-left">
              <div className='flex items-center' data-aos="fade-up">
                <img src={logo} alt="logo" className="w-9 mb-4" />&nbsp;
                <h2 className="mb-2 text-lg font-semibold">KPA.Tech</h2>
              </div>
              <h3 className="text-lg font-semibold mb-2">Address:</h3>
              <p className="mb-2 text-sm">Level 1, 12 Sample St, Sydney NSW 2000</p>
              <h3 className="text-lg font-semibold mb-2">Contact:</h3>
              <p className="mb-2 text-sm">+1 (850) 346-5628</p>
              <p>kpa.techpro@gmail.com</p>
            </div>
            <div data-aos="fade-up">
              <h2 className="text-lg font-semibold mb-4">Services</h2>
              <ul>
                <li>
                  <a href="/portfolio?tab=Mobile%20Application"  className="block hover:text-blue-500 text-sm">Mobile App Development</a>
                  <a href="/portfolio?tab=Web%20Application" className="block hover:text-blue-500 text-sm">Web Application Development</a>
                  <a href="/portfolio" className="block hover:text-blue-500 text-sm">Website Development</a>
                  <a href="/portfolio?tab=Google%20Monetization" className="block hover:text-blue-500 text-sm">Google Adsense Earnings</a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up">
              <h2 className="text-lg font-semibold mb-4">Cases</h2>
              <ul>
                <li>
                  <a href="/case" className="block hover:text-blue-500 text-sm">Website Design</a>
                  <a href="/case" className="block hover:text-blue-500 text-sm">Website Development</a>
                  <a href="/portfolio?tab=Google%20Monetization" className="block hover:text-blue-500 text-sm">Google Adsense</a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up">
              <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
              <ul>
                <li>
                  <span className="block" data-aos="fade-up">
                    <a href="/" className="hover:text-blue-500 text-sm">Home</a>
                  </span>
                  <span className="block" data-aos="fade-up">
                    <a href="/about" className="hover:text-blue-500 text-sm">About Us</a>
                  </span>
                  <span className="block" data-aos="fade-up">
                    <a href="/blogs" className="hover:text-blue-500 text-sm">Blogs</a>
                  </span>
                  
                  <span className="block" data-aos="fade-up">
                    <a href="/contact" className="hover:text-blue-500 text-sm">Contact Us</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr className='py-5 mt-3' ></hr>
          <div className='flex justify-between sm:flex-row flex-col'>
            <p>© 2024 KPA.Tech. All rights reserved</p>
            <div >
              <a href='/#' className='hover:text-blue-500'>Privacy Policy</a>&nbsp;&nbsp;
              <a href='/#' className='hover:text-blue-500'>Terms Of Services</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterSection;
