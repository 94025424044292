// ContactPage.jsx
import React from 'react';
import Header from '../../components/Navbar/navbar';
import InfoSection from '../../components/Info/info';
import Footer from '../../components/Footer/footer';

function Contact() {
  return (
    <div>
      <Header />
      <div className=' pt-[70px]'>
        <InfoSection />
      </div>
      
      <Footer />
    </div>
  );
}

export default Contact;
