import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import dashboard from '../../static/web.png';
import Albino from '../../static/adsense.png';
import Professional from '../../static/professional.png';
import Mobile from '../../static/mobile.png';
import { useNavigate } from 'react-router-dom';


function Services() {
  const navigate = useNavigate();
  const [activeSlider, setActiveSlider] = useState(0);

  const redirectToContacts = () => {
    window.location.href = "/contact";
  };




  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1900,
    arrows: false,
    dots: false,
    vertical: true,
    verticalSwiping: true,
    beforeChange: (current, next) => setActiveSlider(next)
  };

  const handleSliderChange = (index, redirectFunc) => {
    setActiveSlider(index);
    redirectFunc();
  };

  return (
    <div>
      <div className='w-full'>
        <div className='py-10 w-full text-center'>
          <h2 className='text-3xl font-semibold'>Our Services</h2>
          <p className='text-lg font-thin'>We pride ourselves on offering tailored services designed to meet your unique business needs.</p>
        </div>
        <div className='w-full md:pl-10' data-aos="fade-up">
          <div className='md:flex flex-row hidden'>
            <div className='w-full md:w-1/2 overflow-y-auto'>
              <div className="py-4 relative">
                <h2
                  className={`text-xl font-bold mb-2 cursor-pointer ${activeSlider === 0 ? 'text-blue-600' : 'text-black'}`}
                  onClick={() => handleSliderChange(0, () => navigate('/portfolio?tab=Mobile%20Application'))}
                >
                  Expert Mobile App Development
                </h2>
                <p className="text-black relative">We specialize in creating high-quality mobile applications that meet your business needs. Our experienced team of developers uses the latest technologies to deliver exceptional results.</p>
              </div>
              <div className="py-4">
                <h2
                  className={`text-xl font-bold mb-2 cursor-pointer ${activeSlider === 1 ? 'text-blue-600' : 'text-black'}`}
                  onClick={() => handleSliderChange(1, () => navigate('/portfolio?tab=Web%20Application'))}
                >
                  Web Application Development
                </h2>
                <p className="text-black">We offer custom web application development services to help you streamline your business processes and enhance your online presence. Our solutions are tailored to your specific requirements.</p>
              </div>
              <div className="py-4">
                <h2
                  className={`text-xl font-bold mb-2 cursor-pointer ${activeSlider === 2 ? 'text-blue-600' : 'text-black'}`}
                  onClick={() => handleSliderChange(2, () => navigate('/Case'))}
                >
                  Professional Website Development
                </h2>
                <p className="text-black">Our team of skilled designers and developers create stunning websites that are not only visually appealing but also user-friendly. We ensure that your website represents your brand effectively.</p>
              </div>
              <div className="py-4">
                <h2
                  className={`text-xl font-bold mb-2 cursor-pointer ${activeSlider === 3 ? 'text-blue-600' : 'text-black'}`}
                  onClick={() => handleSliderChange(3, () => navigate('/portfolio?tab=Google%20Monetization'))}
                >
                  Maximizing Google AdSense Earnings
                </h2>
                <p className="text-black">Our team of skilled designers and developers create stunning websites that are not only visually appealing but also user-friendly. We ensure that your website represents your brand effectively.</p>
              </div>
            </div>

            <div className='w-[48%]' data-aos="fade-up">
              <Slider {...settings} className="w-full h-full">
                <div className={`slide ${activeSlider === 0 ? 'block' : 'hidden'}`}>
                  <img src={dashboard} alt="logo" className="w-full h-full" />
                </div>
                <div className={`slide ${activeSlider === 1 ? 'block' : 'hidden'}`}>
                  <img src={Albino} alt="logo" className="w-full h-full " />
                </div>
                <div className={`slide ${activeSlider === 2 ? 'block' : 'hidden'}`}>
                  <img src={Professional} alt="logo" className="w-full h-full" />
                </div>
                <div className={`slide ${activeSlider === 3 ? 'block' : 'hidden'}`}>
                  <img src={Mobile} alt="logo" className="w-full h-full" />
                </div>
              </Slider>
            </div>
          </div>

          <div className='block md:hidden'>
            <div>
              <div className="py-4 pl-4" data-aos="fade-up">
                <h2
                  className="text-xl font-bold mb-2 text-blue-600"
                  onClick={() => navigate('/portfolio?tab=Mobile%20Application')}
                >
                  Expert Mobile App Development
                </h2>
                <p className="text-black relative">We specialize in creating high-quality mobile applications that meet your business needs. Our experienced team of developers uses the latest technologies to deliver exceptional results.</p>
              </div>
              <div data-aos="fade-up">
                <img src={Mobile} alt='mobile' />
              </div>
            </div>

            <div>
              <div className="py-4 pl-4" data-aos="fade-up">
                <h2
                  className="text-xl font-bold mb-2 text-blue-600"
                  onClick={() => navigate('/portfolio?tab=Web%20Application')}
                >
                  Web Application Development
                </h2>
                <p className="text-black relative">We specialize in creating high-quality mobile applications that meet your business needs. Our experienced team of developers uses the latest technologies to deliver exceptional results.</p>
              </div>
              <div data-aos="fade-up">
                <img src={dashboard} alt='web' />
              </div>
            </div>

            <div>
              <div className="py-4 pl-4" data-aos="fade-up">
                <h2
                  className="text-xl font-bold mb-2 text-blue-600"
                  onClick={() => navigate('/Case')}
                >
                  Professional Website Development
                </h2>
                <p className="text-black relative">We offer custom web application development services to help you streamline your business processes and enhance your online presence. Our solutions are tailored to your specific requirements.</p>
              </div>
              <div data-aos="fade-up">
                <img src={Professional} alt='professional' />
              </div>
            </div>

            <div>
              <div className="py-4 pl-4" data-aos="fade-up">
                <h2
                  className="text-xl font-bold mb-2 text-blue-600"
                  onClick={() => navigate('/portfolio?tab=Google%20Monetization')}
                >
                  Maximizing Google AdSense Earnings
                </h2>
                <p className="text-black relative">Our team of skilled designers and developers create stunning websites that are not only visually appealing but also user-friendly. We ensure that your website represents your brand effectively.</p>
              </div>
              <div data-aos="fade-up">
                <img src={Albino} alt='adsense' />
              </div>
            </div>
          </div>

          <div className='flex justify-center items-center py-9'>
            <div className='space-x-4'>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={redirectToContacts}>
                Get a Quote
              </button>
              <button className='ring-1 py-2 px-4 rounded hover:text-white hover:bg-blue-500' onClick={() => navigate('/about')}>
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
