import React from 'react';
import Header     from './header.js';
import Main from './main';
import Addition from '../About/addition';
import About from './addition';
import Portfolio from './portfolio';
import WhyChooseUsSection from '../About/preffered';
import InfoSection from '../../components/Info/info';
import Footer from '../../components/Footer/footer';
import Resizable from './testimonial';
import Services from './services';
import OverflowComponent from '../../components/OverLay/overlay';
import CookiesPopup from '../../components/Cookies/cookies.js';
const Home = () => {
    return (
        <div>
            <Header/>
            <Main   />
            <Addition   />
            <Services />
            <About  />
            <CookiesPopup  />
            <OverflowComponent  />
            <Portfolio />
            <WhyChooseUsSection  />
            <Resizable  />
            <InfoSection />
            <Footer />
        </div>
    );
}

export default Home;
