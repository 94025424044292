import Second from "../../static/second2.png";
import Berkerleys from "../../static/berkerley.png";



function Berkerley(){

    return(
        <>
        <div className="md:w-[80%] sm:w-[90%] w-[90%] h-fit bg-gray-50 box-shadow py-10 md:py-20 flex flex-col-reverse md:gap-0 gap-10 md:flex-row md:flex items-center justify-between overflow-hidden rounded-lg ">
      <div className=" w-[90%] md:w-[49%] md:pl-14" data-aos="fade-right">
      <div className=' mb-8 flex items-center ' >
                <span><img src={Berkerleys} className=' mr-2' alt='flagship'/></span>
                <div>
                    <h2 className="text-3xl font-bold text-black">BERKELEY</h2>
                    <h3 className=" font-bold text-sm">DATA STRATEGISTS</h3>
                </div>
                
            </div>
            <p className="text-black mb-5 text-lg font500" >
                    A globally recognized consulting <br/> firm that specializes in the field of <br/> data science and big data analysis.
            </p>
            
            <p className='  text-black-200 text-sm'>
            This achievement stems from KPA's dedication to<br/> innovation, extensive research, and collaboration with<br/> industry experts.
            </p>
            <button className=' px-6 py-2 bg-[#E7C200] rounded-[5px] text-white mt-3'>
                Explore More 
            </button>
            <div>
                <h2 className=' font-bold mt-2'>Check our website</h2>
                <a href="https://web.berkeleydatastrategists.com/" target="_blank" rel="noopener noreferrer">
                                <button className='px-2 py-2 bg-transparent border border-black rounded-[10px] text-black inline-flex items-center space-x-1'>
                                    <span>https://web.berkeleydatastrategists.com/</span>
                                    <i className="fa fa-external-link" aria-hidden="true"></i>
                                </button>
                  </a>
            </div>
        </div>
        <div className=' md:w-1/2 w-full md:block flex justify-center  'data-aos="fade-left">
          <img src={Second } alt='phone ' className=' md:w-4/5 w-4/5 hover:scale-[1.012] transition-[3s] ' />
        </div>
      </div>

        </>
    )
}

export default Berkerley