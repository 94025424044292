import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

function Section() {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const articlesPerPage = 9;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://gnews.io/api/v4/search', {
          params: {
            q: 'technology',
            lang: 'en',
            country: 'us',
            max: articlesPerPage,
            page: currentPage,
            apikey: '9349affff4ff2c3b4101d207e673e7de',
          },
        });
        const fetchedArticles = response.data.articles;

        // Filter out articles without image links
        const filteredArticles = fetchedArticles.filter(article => article.image);

        setArticles(filteredArticles);
        setTotalResults(response.data.totalArticles);
      } catch (error) {
        console.error("Error fetching news articles:", error);
      }
    };

    fetchNews();
  }, [currentPage]);

  const pageCount = Math.ceil(totalResults / articlesPerPage);
  const visiblePages = Math.min(4, pageCount);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, pageCount));
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <section className="flex flex-col justify-center items-center mt-10">
      <div className="sm:w-[95%] w-[97%] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
        {articles.map((article, index) => (
          <div className="bg-white shadow-md rounded-lg p-4" key={index}>
            <div>
              <img src={article.image} alt="news" className="w-full h-[180px] object-cover rounded-md" />
            </div>
            <div className="mt-1">
              <h5 className="text-xs font-semibold">{article.source.name}</h5>
              <h2 className="font-semibold">
                <span>{article.title}</span>
              </h2>
              <p className="text-xs">{article.description}</p>
              <a href={article.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-xs mt-2 inline-block">Read More</a>
            </div>
          </div>
        ))}
      </div>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm py-3 mt-6" aria-label="Pagination">
        <button
          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={mdiChevronLeft} />
          </svg>
        </button>
        {[...Array(visiblePages)].map((_, i) => (
          <button
            key={i + 1}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              currentPage === i + 1 ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            } focus:z-20 focus:outline-offset-0`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          onClick={handleNextPage}
          disabled={currentPage === pageCount}
        >
          <span className="sr-only">Next</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={mdiChevronRight} />
          </svg>
        </button>
      </nav>
    </section>
  );
}

export default Section;
