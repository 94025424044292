import React from 'react';

const ServicesDropdown = ({ isVisible }) => {
    return (
        <div className={`transition-opacity border-b-4 border-blue-600 duration-500 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2'} absolute top-full left-0 w-full bg-white shadow-lg  p-6 font-sans`}>
            <div className='flex flex-col md:gap-x-6 md:flex-row'>
                <div className='md:w-1/2'>
                    <h2 className="text-lg font-semibold mb-4 text-blue-500">Services</h2>
                    <p className="text-sm mb-4">We offer a wide range of services to meet your needs, including mobile app development, web application development, and more.</p>
                    <ul>
                        <li className="mb-2">
                            <a href="/portfolio?tab=Mobile%20Application" className="block hover:text-blue-500 text-sm">Mobile App Development</a>
                        </li>
                        <li className="mb-2">
                            <a href="/portfolio?tab=Web%20Application" className="block hover:text-blue-500 text-sm">Web Application Development</a>
                        </li>
                        <li className="mb-2">
                            <a href="/portfolio" className="block hover:text-blue-500 text-sm">Website Development</a>
                        </li>
                        <li className="mb-2">
                            <a href="/portfolio?tab=Google%20Monetization" className="block hover:text-blue-500 text-sm">Google Adsense Earnings</a>
                        </li>
                    </ul>
                </div>
                <div className='md:w-1/2'>
                    <h2 className="text-lg font-semibold mb-4 text-blue-500">Cases</h2>
                    <ul>
                        <li className="mb-2">
                            <a href="/case" className="block hover:text-blue-500 text-sm">Website Design</a>
                        </li>
                        <li className="mb-2">
                            <a href="/case" className="block hover:text-blue-500 text-sm">Website Development</a>
                        </li>
                        <li className="mb-2">
                            <a href="/portfolio?tab=Google%20Monetization" className="block hover:text-blue-500 text-sm">Google Adsense</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServicesDropdown;
