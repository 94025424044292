import React, { useState, useEffect } from 'react';

const CookiesPopup = () => {
    const [showPreferences, setShowPreferences] = useState(true); // Initially set to true for demonstration

    useEffect(() => {
        const acceptedCookies = localStorage.getItem('acceptedCookies');
        if (acceptedCookies === 'true') {
            setShowPreferences(false); // Hide the cookies popup if the user has already accepted cookies
        }
    }, []);

    const handleAcceptAllClick = () => {
        // Add logic to accept all cookies
        localStorage.setItem('acceptedCookies', 'true'); // Set acceptedCookies to true in local storage
        setShowPreferences(false); // Close the cookies popup after accepting all cookies
    };

    const handleCancelClick = () => {
        // Add logic to cancel cookie preferences
        setShowPreferences(false); // Close the cookies popup without accepting any cookies
    };

    return (
        <>
            {/* Cookies popup section */}
            {showPreferences && (
                <section className="fixed p-4 ml-auto bg-white border border-gray-200 bottom-0 left-0 w-full md:max-w-md md:right-0 md:transform md:-translate-x-1/2 md:rounded-2xl z-50 animate-slide-up">
                    <h2 className="font-semibold text-gray-800 dark:text-black">🍪 We use cookies!</h2>
                    <p className="mt-4 text-sm text-gray-600 dark:text-gray-700">Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. </p>
                    <p className="mt-3 text-sm text-gray-600 dark:text-gray-700">Closing this modal default settings will be saved.</p>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <button className="text-xs bg-blue-500 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none" onClick={handleAcceptAllClick}>
                            Accept 
                        </button>
                        <button className="text-xs border text-gray-800 hover:bg-gray-100 dark:border-blue-500 dark:text-black dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none" onClick={handleCancelClick}>
                            Cancel
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};

export default CookiesPopup;
