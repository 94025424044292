import React, { useState } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Logo from './components/Navbar/logo.png';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Roboto, sans-serif',
  headerBgColor: '#0056b3',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#0056b3',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

function CustomChatBot() {
  const [userInfo, setUserInfo] = useState({});
  const [selectedService, setSelectedService] = useState('');

  const handleEnd = ({ steps, values }) => {
    if (values) {
      setUserInfo({ ...userInfo, ...values });
    }
  };

  const handleServiceSelection = (value) => {
    setSelectedService(value);
    return 'confirmServicesOptions';
  };
  return (
    <ThemeProvider theme={theme}>
    <ChatBot
      headerTitle="KPA.Tech"
      handleEnd={handleEnd}
      botAvatar={Logo}
      steps={[
        {
          id: '0',
          message: 'Welcome to KPA.Tech! How can we assist you today?',
          trigger: '1',
        },
        {
          id: '1',
          message: 'Before we proceed, may I have your name?',
          trigger: 'name',
        },
        {
          id: 'name',
          user: true,
          trigger: '2',
          validator: (value) => {
            if (!value) {
              return 'Please enter your name.';
            }
            return true;
          },
        },
        {
          id: '2',
          message: 'Hi {previousValue}, may I have your phone number?',
          trigger: 'phone',
        },
        {
          id: 'phone',
          user: true,
          trigger: '3',
          validator: (value) => {
            if (!value) {
              return 'Please enter your phone number.';
            }
            return true;
          },
        },
        {
          id: '3',
          message: 'Great. Lastly, can you provide your email address?',
          trigger: 'email',
        },
        {
          id: 'email',
          user: true,
          trigger: '4',
          validator: (value) => {
            if (!value) {
              return 'Please enter your email address.';
            }
            return true;
          },
        },
        {
          id: '4',
          message: 'Thank you, {previousValue}. Here are our services:',
          trigger: 'services',
        },
        {
          id: 'services',
          options: [
            { value: 'web', label: 'Web Development', trigger: () => handleServiceSelection('Web Development') },
            { value: 'mobile', label: 'Mobile App Development', trigger: () => handleServiceSelection('Mobile App Development') },
            { value: 'ai', label: 'AI Solutions', trigger: () => handleServiceSelection('AI Solutions') },
            { value: 'cloud', label: 'Cloud Services', trigger: () => handleServiceSelection('Cloud Services') },
          ],
        },
        {
          id: 'confirmServicesOptions',
          message: ({ previousValue }) => `You selected ${selectedService}. Is this correct?`,
          trigger: 'confirmServices',
        },
        {
          id: 'confirmServices',
          options: [
            { value: 'yes', label: 'Yes', trigger: 'endMessage' },
            { value: 'no', label: 'No', trigger: 'services' },
          ],
        },
        {
          id: 'endMessage',
          message: ({ previousValue }) => {
            return `Thank you, ${userInfo.name}! Your information:
              Name: ${userInfo.name}
              Phone: ${userInfo.phone}
              Email: ${userInfo.email}
              Selected Service: ${selectedService}
            Our team will get in touch with you shortly.`;
          },
          end: true,
        },
      ]}
      floating={true} 
    />
    </ThemeProvider>
  );
}

export default CustomChatBot;
