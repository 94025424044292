import { useState } from "react";
import React from 'react';


function Main() {
  const [showMenu, setShowMenu] = useState(false);
  const [activeTab, setActiveTab] = useState('All Posts'); // State to track the active tab

  // Function to handle click on menu items
  const handleMenuItemClick = (tabName) => {
    setActiveTab(tabName); // Set the active tab to the clicked tab
    setShowMenu(false); // Hide the menu on small screens after clicking
  };

  return (
    <main className="w-full h-[350px] flex justify-center items-center pt-20 main-blog">
      <div className="w95">
        <h2 className="text-4xl text-blue-500 font-semibold mb-1 mt-6">Blog and News</h2>
        <p className="font-sans text-sm font-light mb-6">
          <span>We're passionate about sharing our knowledge and</span> <br/>
          <span>expertise to help businesses thrive in the digital landscape.</span>
        </p>
        <div className='w-full'>
          <button 
            className="md:hidden bg text-white p-2 rounded-md" 
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {showMenu ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
          {/* Menu for small screens */}
          {showMenu && (
            <div className="whitespace-no-wrap text-sm font-bold absolute md:hidden">
              <button 
                className={`block w-full text-left px-4 py-3 ${activeTab === 'All Posts' ? 'bg-[#0391C1] font-semibold text-black' : 'bg-gray-200 font-semibold text-black'} rounded-t-md`} 
                onClick={() => handleMenuItemClick('All Posts')}
              >
                All Posts
              </button>
              <button 
                className={`block w-full text-left px-4 py-3 ${activeTab === 'Industry Trends' ? 'bg-[#0391C1] font-semibold text-black' : 'bg-gray-200 font-semibold text-black'}`} 
                onClick={() => handleMenuItemClick('Industry Trends')}
              >
                Industry Trends
              </button>
              <button 
                className={`block w-full text-left px-4 py-3 ${activeTab === 'Success Stories' ? 'bg-[#0391C1] font-semibold text-black' : 'bg-gray-200 font-semibold text-black'}`} 
                onClick={() => handleMenuItemClick('Success Stories')}
              >
                Success Stories
              </button>
              <button 
                className={`block w-full text-left px-4 py-3 ${activeTab === 'Company Updates' ? 'bg-[#0391C1] font-semibold text-black' : 'bg-gray-200 font-semibold text-black'}`} 
                onClick={() => handleMenuItemClick('Company Updates')}
              >
                Company Updates
              </button>
              <button 
                className={`block w-full text-left px-4 py-3 ${activeTab === 'Expert Interviews' ? 'bg-[#0391C1] font-semibold text-black' : 'bg-gray-200 font-semibold text-black'} rounded-b-md`} 
                onClick={() => handleMenuItemClick('Expert Interviews')}
              >
                Expert Interviews
              </button>
            </div>
          )}
        </div>
        <div className="hidden md:block whitespace-no-wrap text-sm">
          <button 
            className={`px-4 py-3 font-thin ${activeTab === 'All Posts' ? 'bg-[#0391C1] text-white' : 'bg-gray-200 text-black'} rounded-l-md inline-block`} 
            onClick={() => handleMenuItemClick('All Posts')}
          >
            All Posts
          </button>
          <button 
            className={`px-4 py-3 font-thin ${activeTab === 'Industry Trends' ? 'bg-[#0391C1] text-white' : 'bg-gray-200 text-black'} inline-block`} 
            onClick={() => handleMenuItemClick('Industry Trends')}
          >
            Industry Trends
          </button>
          <button 
            className={`px-4 py-3 font-thin ${activeTab === 'Success Stories' ? 'bg-[#0391C1] text-white' : 'bg-gray-200 text-black'} inline-block`} 
            onClick={() => handleMenuItemClick('Success Stories')}
          >
            Success Stories
          </button>
          <button 
            className={`px-4 py-3 font-thin ${activeTab === 'Company Updates' ? 'bg-[#0391C1] text-white' : 'bg-gray-200 text-black'} inline-block`} 
            onClick={() => handleMenuItemClick('Company Updates')}
          >
            Company Updates
          </button>
          <button 
            className={`px-4 py-3 font-thin ${activeTab === 'Expert Interviews' ? 'bg-[#0391C1] text-white' : 'bg-gray-200 text-black'} rounded-r-md inline-block`} 
            onClick={() => handleMenuItemClick('Expert Interviews')}
          >
            Expert Interviews
          </button>
        </div>
      </div>
    </main>
  );
}

export default Main;
