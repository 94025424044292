import React from 'react';

const Overlay = ({ show, onClose, children }) => {
  const overlayStyle = {
    transition: 'opacity 0.3s ease',
    opacity: show ? 1 : 0,
    visibility: show ? 'visible' : 'hidden'
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-[5px] bg-cover bg-center" style={overlayStyle}>
      <div className="bg-white p-4 rounded-lg max-w-screen-md w-full border ">
        <div className="absolute top-0 right-0 pt-4 pr-4 hidden">
          <button onClick={onClose}>
            <svg className="w-10 h-10" fill="none" stroke="#DDD" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Overlay;
