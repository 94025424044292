import React from 'react';
import Header from '../../components/Navbar/navbar';
import InfoSection from '../../components/Info/info';
import Footer from '../../components/Footer/footer';
import Main from './main';
import Addition from './addition';
import WhyChooseUsSection from './preffered';
import Process from './process';



function About() {
  

  return (
    <div>
      <Header />
      <Main/>
      <Addition />
      <Process />
      <WhyChooseUsSection />
      <InfoSection />
      <Footer />
    </div>
  );
}

export default About;

