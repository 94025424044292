import braids from "../../static/Braids.png";



function Tanias(){

    return(
        <>
        <div className="md:w-[80%] sm:w-[90%] w-[90%] h-fit bg-pink-200 box-shadow   flex flex-col-reverse md:gap-0 gap-10 md:flex-row md:flex items-center justify-between overflow-hidden rounded-lg  ">
             <div className=" w-[90%] md:w-[49%] md:pl-14" data-aos="fade-right">
                <div className=" mb-3">
                    <h2 className="text-3xl font-thin font text-black">Tania Braids</h2>
                </div>
                        <p className="text-black mb-5 text-lg font500" >
                        Tania's Braids offer a range of hair braiding  <br/>services designed to elevate your <br/> appearance and boost your confidence.
                        </p>
                        
                        <p className='  text-black-200 text-sm'>
                        This achievement stems from KPA's dedication to<br/> innovation, extensive research, and collaboration with<br/> industry experts.
                        </p>
                        <button className=' px-6 py-2 bg-[#E7C200] rounded-[5px] text-white mt-3'>
                            Explore More 
                        </button>
                        <div>
                            <h2 className=' font-bold mt-2'>Check our website</h2>
                            <a href="https://taniasbraids.pages.dev/" target="_blank" rel="noopener noreferrer">
                                            <button className='px-2 py-2 bg-transparent border border-black rounded-[10px] text-black inline-flex items-center space-x-1'>
                                                <span>https://taniasbraids.pages.dev/</span>
                                                <i className="fa fa-external-link" aria-hidden="true"></i>
                                            </button>
                            </a>
                        </div>
                    </div>
                    <div className=' md:w-1/2 w-full md:block flex  justify-between mt-auto md:pt-20 flex-col' data-aos="fade-left">
                    <div></div>
                    <img src={braids } alt='phone ' className=' md:w-4/5 w-4/5 hover:scale-[1.012] transition-[3s] ' />
                    </div>
        </div>

        </>
    )
}

export default Tanias