

function About(){
    
    return(
        <div className='newsletter01 h-fit py-5 px-7' data-aos="fade-up">
            <h2 className=' text-2xl text-white font-semibold'><span style={{ color: 'white' }}>&#8594;</span>What you should know</h2><br></br>
            <p className=' font-thin  text-white'><span className=' text-yellow-400  '>KPA.Tech </span>is a pioneering firm dedicated to accelerating the growth of businesses through cutting-edge technology solutions. Our holistic approach encompasses a wide range of services tailored to meet the unique needs of each client, ensuring unparalleled results and long-term success. With a blend of expertise, creativity, and unwavering dedication, we're here to propel your business to new heights</p>
        </div>
    );
}

export default About;