import React from 'react';
import Header from '../../components/Navbar/navbar';
import InfoSection from '../../components/Info/info';
import Footer from '../../components/Footer/footer';
import Project from './project';
import  '../../css/portfolio.css';


function Portfolio(){
    return(
        <div>
            <Header />
            <Project  />
            <InfoSection />
            <Footer />
        </div>
    );
}

export default Portfolio;