
import Apple from '../../static/apple.png';
import Google from '../../static/google.png';
import Wave2 from '../../static/svg/wave2.svg';
import Second from "../../static/second2.png";
import Berkerley from "../../static/berkerley.png";
import Macbook from "../../static/macbook.png";
import chowdeck from "../../static/chowdeck.png";
import chowdecks from "../../static/Instas.svg";
import Insta from "../../static/insta.png";
import Wave from '../../static/svg/wave.svg';
import { useNavigate } from 'react-router-dom';


function Portfolio() {
  const navigate = useNavigate();

  const redirect = () =>{
    navigate('/case')
  };

  return (

    <div className=' w-full'>
      <div className="bg-[#7B11F9] w-full m-0 p-0">
      <div className=" w-full h-fit bg-[#7B11F9]  py-10 md:py-20 flex flex-col-reverse md:gap-0 gap-10 md:flex-row md:flex items-center justify-between overflow-hidden ">
      <div className=" w-[90%] md:w-[49%] md:pl-14"data-aos="fade-up" >
          <div className=' mb-8 flex items-center ' >
                <span><img src={chowdecks} className=' mr-2' alt='chowdeck'/></span>
               
            </div>
            <p className="text-white mb-5 font500">
                <span>A powerful platform designed to help you  </span> <br />
                <span>create campaigns, generate leads, and </span><br />
                <span>automatically engage with your prospects.</span>
            </p>
            <p className='  text-white  text-sm'>
            Through iterative development and continuous refinement, <br/>KPA ensured that the platform offers intuitive functionality, <br/> seamless user experience, and effective performance
            </p>
            <h6 className="font-bold text-sm mb-1 pt-6">
                <span>DOWNLOAD OUR</span><br /><span>APP</span>
            </h6>
            <div className="flex gap-2 mb-4">
                <img src={ Apple } alt="apple" className="w-32" />
                <img src={ Google} alt="google" className="w-32" />
            </div>
            <button className="px-4 py-3 bg-[white] rounded-lg text-black font-sans text-sm font-bold">
            Explore More
            </button>
                            
        </div>
        <div className=' md:w-1/2 w-full md:block flex justify-center  ' data-aos="fade-up">
          <img src={Insta} alt=''  className=' w-4/5 hover:scale-[1.012] transition-[3s]'/>
        </div>
       
      </div>
      <div className=' m-0 p-0 '>
        <img src={Wave} alt='' className=' ' />
      </div>
      </div>
      <div className=' w-full newletter01 bg-slate-100'>
        <div className='flex flex-col-reverse md:flex-row gap-4 py-10'>
          <div className='flex flex-col items-center justify-center w-full md:w-1/2' >
            <div className='pl-3 md:pl-0'>
              <div className=' mb-8 flex items-center '>
                <span><img src={Berkerley} className=' mr-2' alt='flagship' /></span>
                <div>
                  <h2 className="text-3xl font-bold text-black">BERKELEY</h2>
                  <h3 className=" font-bold text-sm">DATA STRATEGISTS</h3>
                </div>
              </div>
              <p className="text-black mb-5 text-lg font500">
                A globally recognized consulting <br /> firm that specializes in the field of <br /> data science and big data analysis.
              </p>
              <p className='  text-black-200 text-sm'>
                This achievement stems from KPA's dedication to<br /> innovation, extensive research, and collaboration with<br /> industry experts.
              </p>
              <button className=' px-6 py-2 bg-[#E7C200] rounded-[5px] text-white mt-3'>
                Explore More
              </button>
              <div>
                <h2 className=' font-bold mt-2'>Check our website</h2>
                <a href="https://web.berkeleydatastrategists.com/" target="_blank" rel="noopener noreferrer">
                  <button className='px-2 py-2 bg-transparent border border-black rounded-[10px] text-black inline-flex items-center space-x-1'>
                    <span>https://web.berkeleydatastrategists.com/</span>
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className=' md:w-1/2 w-[95%] pl-3 md:pl-0' data-aos="fade-up">
            <img
              alt=''
              className=' md:w-[90%] w-[95%] hover:scale-[1.05] transition-[3s] mx-auto'
              src={Second}
            />
          </div>
        </div>
      </div>

      <div className=' w-full newletter01 newsletter01' data-aos="fade-up">
        <img src={Wave2} className=' z-10' alt='' />
        <div className='flex flex-col-reverse md:flex-row gap-4 py-10'>
          <div className='flex flex-col items-center justify-center w-full md:w-1/2' data-aos="fade-up">
            <div className='pl-3 md:pl-0'>
              <div className=' mb-8 flex items-center '>
                <span><img src={chowdeck} className=' mr-2' alt='chowdeck' /></span>
                <h2 className="text-4xl font-normal text-white">Chowdeck</h2>
              </div>
              <p className="text-white mb-5 font500">
                <span>Chowdeck is a technology company that </span> <br />
                <span>provides logistics services to both vendors</span><br />
                <span>and consumers.</span>
              </p>
              <p className='  text-white  text-sm'>
                KPA remains committed to driving innovation and <br /> transforming the logistics landscape for the betterment of <br /> industry experts.
              </p>
              <h6 className="font-bold text-sm mb-1 pt-6">
                <span>DOWNLOAD OUR</span><br /><span>APP</span>
              </h6>
              <div className="flex gap-2 mb-4">
                <img src={Apple} alt="apple" className="w-32" />
                <img src={Google} alt="google" className="w-32" />
              </div>
              <button className="px-4 py-3 bg-[#FFC501] rounded-lg text-white font-sans text-sm font-bold" onClick={redirect}>
                Explore More
              </button>
            </div>
          </div>
          <div className=' md:w-1/2 w-[95%] pl-3 md:pl-0' data-aos="fade-up">
            <img
              alt=''
              className=' md:w-3/5 w-[75%] hover:scale-[1.05] transition-[3s] mx-auto '
              src={Macbook}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;