import React   from 'react';
import '../../css/about.css';


function Process() {


  return (
    <section className="flex flex-col justify-center items-center">
      <div className="pt-10  w91">
        <div className="flex justify-center process">
          <h2 className="text-center m-auto">Our Process</h2>
        </div>
        <div className="pl-2 mt-4 smfull">
          <p className='w-cancel'>We follow a meticulous process that ensures transparency, collaboration, and, most importantly, the delivery of high-quality solutions that exceed expectations.</p>
        </div>
      </div>

      {/* Process 01 */}
      <div className="flex relative mt-10 items-center w91" data-aos="fade-up" id="div1">
        <div className="bg-white box justify-between items-center flex relative holder">
          <div className="h-4/5 pl-24 pl-cancel">
            <h1 className="text-9xl font-bold text-gray-400 font-sans d-none">01</h1>
            <h2 className="text-xl font-bold text-blue-600 font-inter">Discovery and Planning</h2>
            <p className="w-1/2 w-cancel">Every successful project begins with a thorough understanding of our client's goals, objectives, and requirements. We start by conducting in-depth discussions to gather insights and define project scope. This phase lays the foundation for a successful partnership and ensures alignment between our team and our clients.</p>
          </div>
          <div className="discovery bs bg-gray-500 absolute right-0 top-1/2 transform translate-x-2/3 -translate-y-1/2 mt-1 z-10 odd" ></div>
        </div>
      </div>

      {/* Process 02 */}
      <div className="flex relative mt-10 m10 items-center justify-end w91 j-e" data-aos="fade-up" id="div2">
        <div className="bg-white box justify-between items-center flex relative even-holder">
          <div className="prototype bs bg-gray-500 absolute top-1/2 transform -translate-x-2/3 -translate-y-1/2 mt-1 z-10 even"></div>
          <div className="h-4/5 pr-24 w-full flex flex-col justify-center items-end al-cancel pr-cancel">
            <h1 className="text-9xl font-bold text-gray-400 font-sans d-none">02</h1>
            <div className="flex flex-col items-end al-cancel">
              <h2 className="text-xl font-bold text-blue-600 font-inter">Design and Prototyping</h2>
              <p className="w-1/2 text-right w-cancel tr-cancel">With a clear understanding of the project requirements, our talented designers and developers collaborate to create detailed wireframes, mockups, and prototypes. This iterative process allows us to visualize the final product and gather feedback from our clients, ensuring that we're on the right track before moving forward.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Process 03 */}
      <div className="flex relative mt-10 m10 items-center w91" data-aos="fade-up" id="div3">
        <div className="bg-white box justify-between items-center flex relative holder">
          <div className="h-4/5 pl-24 pl-cancel">
            <h1 className="text-9xl font-bold text-gray-400 font-sans d-none">03</h1>
            <h2 className="text-xl font-bold text-blue-600 font-inter">Development and Testing</h2>
            <p className="w-1/2 w-cancel">Once the design is approved, our development team gets to work bringing the project to life. Using cutting-edge technologies and best practices, we build robust, scalable solutions that meet the highest standards of quality. Throughout the development process, we conduct rigorous testing to identify and address any issues, ensuring a seamless user experience.</p>
          </div>
          <div className="three bs bg-gray-500 absolute right-0 top-1/2 transform translate-x-2/3 -translate-y-1/2 mt-1 z-10 odd"></div>
        </div>
      </div>

      {/* Process 04 */}
      <div className="flex relative mt-10 m10 items-center justify-end w91 j-e" data-aos="fade-up" id="div4">
        <div className="bg-white box justify-between items-center flex relative even-holder">
          <div className="four bs bg-gray-500 absolute top-1/2 transform -translate-x-2/3 -translate-y-1/2 mt-1 z-10 even"></div>
          <div className="h-4/5 pr-24 w-full flex flex-col justify-center items-end al-cancel pr-cancel">
            <h1 className="text-9xl font-bold text-gray-400 font-sans d-none">04</h1>
            <div className="flex flex-col items-end al-cancel">
              <h2 className="text-xl font-bold text-blue-600 font-inter">Deployment and Launch</h2>
              <p className="w-1/2 text-right w-cancel tr-cancel">With the project completed and thoroughly tested, we prepare for deployment and launch. Our team handles all aspects of deployment, including server setup, configuration, and optimization. We work closely with our clients to coordinate the launch, ensuring a smooth transition and minimal downtime.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Process 05 */}
      <div className="flex relative mt-10 m10 items-center w91" data-aos="fade-up" id="div5">
        <div className="bg-white box justify-between items-center flex relative holder">
          <div className="h-4/5 pl-24 pl-cancel">
            <h1 className="text-9xl font-bold text-gray-400 font-sans d-none">05</h1>
            <h2 className="text-xl font-bold text-blue-600 font-inter">Support and Maintenance</h2>
            <p className="w-1/2 w-cancel">Our commitment to our clients doesn't end with project delivery. We provide ongoing support and maintenance to ensure that our solutions remain secure, reliable, and up-to-date. Whether it's addressing technical issues, implementing updates, or providing training and documentation, we're here to support our clients every step of the way.</p>
          </div>
          <div className="five bs bg-gray-500 absolute right-0 top-1/2 transform translate-x-2/3 -translate-y-1/2 mt-1 z-10 odd"></div>
        </div>
      </div>

    </section>
  );
}

export default Process;
