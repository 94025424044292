import React, { useState, useEffect } from 'react';
import Header from '../Navbar/navbar';
import { Vortex } from 'react-loader-spinner';

const Loader = () => {
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    // Function to check network status
    const checkNetworkStatus = () => {
      // Simulate network request
      const isNetworkStable = Math.random() < 0.9; // Simulate 90% chance of stable network

      if (isNetworkStable) {
        // If network is stable, set loading to false after a delay
        setTimeout(() => {
          setLoading(false);
          setNetworkError(false);
        }, 2000); // Simulated delay
      } else {
        // If network is not stable, set networkError to true
        setNetworkError(true);
      }
    };

    // Initial network status check
    checkNetworkStatus();

    // Set interval to continuously check network status
    const networkCheckInterval = setInterval(checkNetworkStatus, 5000); // Check every 5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(networkCheckInterval);
  }, []);

  return (
    <>
      {loading && (
        <div>
          <Header />
          <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-transparent bg-opacity-5 z-50">
            <Vortex
              visible={true}
              height="120"
              width="120"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
            <h2 className="mt-4 text-2xl font-bold text-gradient animate-bounce">KPA.Tech</h2>
          </div>
        </div>
      )}
      {!loading && networkError && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-red-500 text-white z-50">
          <p className="text-lg">Network not stable. Please check your internet connection.</p>
        </div>
      )}
    </>
  );
};

export default Loader;
