import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import Loader from '../../components/Loader/loader';
import Berkerley from './berkerly';
import Chowdeck from './chowdeck';
import Instas from './insta';
import Tanias from './tania';

const Project = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeTab, setActiveTab] = useState('All Projects');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  const handleMenuItemClick = (tabName) => {
    setIsLoading(true);
    setActiveTab(tabName);
    setShowMenu(false);
    navigate(`?tab=${tabName}`); // Update URL with the new tab
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [activeTab]);

  return (
    <>
      <div className="w-full bg-black portfolio-container">
        <div className="flex flex-col items-center pt-[120px] gap-2">
          <h2 className="text-white text-3xl font-semibold text-center">Explore Our Portfolio</h2>
          {/* Hamburger menu */}
          <div className='w-full'>
            <button
              className="md:hidden bg text-white p-2 rounded-md"
              onClick={() => setShowMenu(!showMenu)}
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                {showMenu ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
            {/* Menu for small screens */}
            {showMenu && (
              <div className="whitespace-no-wrap text-sm font-bold absolute md:hidden">
                <button
                  className={`block w-full text-left px-4 py-3 ${activeTab === 'All Projects' ? 'bg-[#0391C1] text-white font-semibold rounded-t-md' : 'bg-blue-900 text-white font-semibold'}`}
                  onClick={() => handleMenuItemClick('All Projects')}
                >
                  All Projects
                </button>
                <button
                  className={`block w-full text-left px-4 py-3 ${activeTab === 'Mobile Application' ? 'bg-[#0391C1] text-white font-semibold' : 'bg-blue-900 text-white font-semibold'}`}
                  onClick={() => handleMenuItemClick('Mobile Application')}
                >
                  Mobile Application
                </button>
                <button
                  className={`block w-full text-left px-4 py-3 ${activeTab === 'Web Application' ? 'bg-[#0391C1] text-white font-semibold' : 'bg-blue-900 text-white font-semibold'}`}
                  onClick={() => handleMenuItemClick('Web Application')}
                >
                  Web Application
                </button>
                <button
                  className={`block w-full text-left px-4 py-3 ${activeTab === 'Google Monetization' ? 'bg-[#0391C1] text-white font-semibold rounded-b-md' : 'bg-blue-900 text-white font-semibold'}`}
                  onClick={() => handleMenuItemClick('Google Monetization')}
                >
                  Google Monetization
                </button>
              </div>
            )}
          </div>
          {/* Menu for larger screens */}
          <div className="hidden md:flex whitespace-no-wrap text-sm font-bold mt-0 pb-14">
            <button
              className={`px-4 py-3 font-semibold ${activeTab === 'All Projects' ? 'bg-[#0391C1] text-white' : 'bg-blue-900 text-white'} rounded-l-md inline-block`}
              onClick={() => handleMenuItemClick('All Projects')}
            >
              All Projects
            </button>
            <button
              className={`px-4 py-3 ${activeTab === 'Mobile Application' ? 'bg-[#0391C1] text-white font-semibold' : 'bg-blue-900 text-white font-semibold'} inline-block`}
              onClick={() => handleMenuItemClick('Mobile Application')}
            >
              Mobile Application
            </button>
            <button
              className={`px-4 py-3 ${activeTab === 'Web Application' ? 'bg-[#0391C1] text-white font-semibold' : 'bg-blue-900 text-white font-semibold'} inline-block`}
              onClick={() => handleMenuItemClick('Web Application')}
            >
              Web Application
            </button>
            <button
              className={`px-4 py-3 ${activeTab === 'Google Monetization' ? 'bg-[#0391C1] text-white font-semibold' : 'bg-blue-900 text-white font-semibold'} rounded-r-md inline-block`}
              onClick={() => handleMenuItemClick('Google Monetization')}
            >
              Google Monetization
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <section className='mb-6'>
          {activeTab === 'All Projects' && (
            <div className=" pt-2 md:pt-0 md:relative bottom-10 flex flex-col justify-center items-center gap-10">
              <Berkerley />
              <Chowdeck />
              <Instas />
              <Tanias />
            </div>
          )}
          {activeTab === 'Mobile Application' && (
            <div className=" pt-2 md:pt-0 md:relative bottom-10 flex flex-col justify-center items-center gap-10">
              <Chowdeck />
            </div>
          )}
          {activeTab === 'Web Application' && (
            <div className=" pt-2 md:pt-0 md:relative bottom-10 flex flex-col justify-center items-center gap-10">
              <Instas />
              <Chowdeck />
              <Berkerley />
              <Tanias />
            </div>
          )}
          {activeTab === 'Google Monetization' && (
  <h2 className='text-6xl font-bold text-gray-700 text-center mt-2'>Coming soon</h2>
)}
        </section>
      )}
    </>
  );
}

export default Project;
