// Main.js

import React from 'react';
import  '../../css/about.css';
import { useNavigate } from 'react-router-dom';


function Main() {
  const navigate = useNavigate();

  const redirect = () =>{
    navigate('/contact')
  };
  return (
    <div className="w-full about h-[70vh]  md:h-[90vh] pt-[70px] flex justify-center items-center">
    <div className="text-center md:w-3/4 lg:w-1/2">
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-white">We're your trusted partners in driving growth and innovation.</h2>
      <p className="text-sm md:text-lg lg:text-xl text-white mb-6">At KPA.Tech, we pride ourselves on offering tailored services designed to meet your unique business needs. Our approach is centered around collaboration, innovation, and reliability, ensuring that we deliver solutions that drive tangible results and propel your business forward</p>
      <div className="space-x-4">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={redirect}>Book a session</button>
      </div>
    </div>
  </div>
  );
}

export default Main;


