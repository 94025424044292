import React from 'react';
import Willey from '../../static/willey.png';
import Shades from  '../../static/shade.png';
import Realyou from '../../static/realyou.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Resizable() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className='bg-[#161C2D]'>
        <div className='py-10'>
          <h2 className='text-3xl text-white text-center'>Customer testimonials </h2>
          <p className='text-center text-white text-lg py-3'> Read what our customers have to say</p>
        </div>

        <div className='md:w-[80%] sm:w-[90%] w-[100%] mx-auto pb-8'>
          <Slider {...settings}>
            <div className="w-[280px] border border-white rounded-lg p-6 mr-10">
              <h2 className="text-xl text-white font-[500] mb-4" style={{ fontFamily: 'Livvic, sans-serif' }}>Albino</h2>
              <p className="text-white mb-4">KPA.Tech is simply outstanding! Their commitment to excellence and attention to detail are unmatched. From conceptualization to execution, their team went above and beyond to ensure that our project was a resounding success.</p>
              <div className="flex items-center">
                <img src={Willey} alt="Jenny Wilson" className="w-12 h-12 rounded-full object-cover mr-4"/>
                <div>
                  <p className="text-white font-bold">Jenny Wilson</p>
                  <p className="text-gray-600">CEO, Albino</p>
                </div>
              </div>
              <div className='pt-3 pb-1'>
                <a href='/portfolio?tab=Mobile%20Application' className=' text-blue-400'>Read case study</a>
              </div>
            </div>

            <div className="w-[280px] border border-white rounded-lg p-6 mr-10">
              <h2 className="text-xl text-white font-[500] mb-4" style={{ fontFamily: 'Livvic, sans-serif' }}>Shade</h2>
              <p className="text-white mb-4">Working with KPA.Tech has been a game-changer for our business. Their professional team consistently delivered high-quality work on time and within budget. Their dedication and customer-centric approach truly set them apart from the competition.</p>
              <div className="flex items-center">
                <img src={Shades} alt="Jenny Wilson" className="w-12 h-12 rounded-full object-cover mr-4"/>
                <div>
                  <p className="text-white font-bold">Jacobs Jones</p>
                  <p className="text-gray-600">CEO, Shade</p>
                </div>
              </div>
              <div className='pt-3 pb-1'>
                <a href='/portfolio?tab=Web%20Application' className=' text-blue-400'>Read case study</a>
              </div>
            </div>

            <div className="w-[280px] border border-white rounded-lg p-6 mr-10">
              <h2 className="text-xl text-white font-[500] mb-4" style={{ fontFamily: 'Livvic, sans-serif' }}>Realyou</h2>
              <p className="text-white mb-4">KPA.Tech has been instrumental in transforming our ideas into reality. Their innovative approach and technical expertise provided us with a cutting-edge solution that exceeded our expectations. We couldn't be happier with the results.</p>
              <div className="flex items-center">
                <img src={Realyou} alt="Jenny Wilson" className="w-12 h-12 rounded-full object-cover mr-4"/>
                <div>
                  <p className="text-white font-bold">Cameron Williamson</p>
                  <p className="text-gray-600">CEO, RealYou</p>
                </div>
              </div>
              <div className='pt-3 pb-1'>
                <a href='/portfolio?tab=Web%20Application' className=' text-blue-400'>Read case study</a>
              </div>
            </div>

           
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Resizable;
