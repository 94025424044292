import React, { useState } from 'react';
import { FiPaperclip } from 'react-icons/fi'; // Import the Paperclip icon from react-icons/fi

const ContactOverlay = ({onClose}) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-[2px] bg-cover bg-center">
           
                
            <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg sm:w-[500px] w-full">
                <h2 className="text-2xl font-semibold text-blue-500 mb-4">Kickstart your Project</h2>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-600">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="phone" className="block text-gray-600">Phone Number:</label>
                    <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-600">Message:</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        rows="4"
                        required
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label htmlFor="files" className="block text-gray-600">Add Photos or Documents:</label>
                    {/* Use FiPaperclip icon instead of input */}
                    <label htmlFor="files" className="flex items-center cursor-pointer text-blue-500">
                        <FiPaperclip className="mr-2" />
                        Attach File
                    </label>
                    <input
                        type="file"
                        id="files"
                        className="hidden"
                        multiple
                    />
                </div>
                <div className=' flex justify-between'>
                    <button type="submit" className="bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none hover:bg-blue-700 transition duration-300">Submit</button>
                    <button onClick={onClose} className="text-white py-2 px-4 bg-red-600 hover:bg-red-800 focus:outline-none rounded mr-5">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactOverlay;
