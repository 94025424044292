import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Portfolio from './pages/Portfolio/portfolio.js'; 
import About from './pages/About/about.js'; 
import Contact from './pages/Contact/contact.js';
import Blogs from './pages/Blogs/blog.js';
import Home from './pages/Home/index.js';
import Loader from './components/Loader/loader.js'; 
import ThankYouPage from './components/Bookings/books.js';
import Case from './pages/Case/case.js';

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay with useEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
     
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/case" element={<Case />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/about" element={<About />} />
            <Route path="/approved" element={<ThankYouPage />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
